<template>
    <div style="margin-top: 80px;">
        <BasePageBreadCrumb :pages="pages" :title="id ? 'Editar corretor' : 'Novo corretor'" :menu="menu"/>
        <div class="container-fluid qsuite-c-fluid-0">
            <div class="col-12">
                <div class="row">
                    <div class="col-12">
                        <div class="card card-bg-trans">
                            <div class="card-body pt-4 px-0">
                                <b-tabs>
                                    <form class="px-2 needs-validation" validate @submit.prevent="save">    
                                        <b-tab class="tab-pane" id="dadospessoais">
                                            <template #title>
                                                <div class="d-flex">
                                                    <i class="far fa-list-alt font-20 align-middle mr-2"></i><span class="d-none d-lg-block"> Dados pessoais <b-badge variant="danger" v-show="error_information">1</b-badge></span>
                                                </div>
                                            </template>
    
                                            <div class="tab-content">
                                <div class="tab-pane show active" id="dadospessoais">
                                  
                                        <div class="form-body">
                                            <div class="row">
                                                <div class="col-12 col-md-6 col-lg-3">
                                                    <div class="form-group">
                                                        <label>Código do corretor</label>
                                                        <input type="text" v-model="broker.id" class="form-control" placeholder="#5898" disabled="">
                                                    </div>
                                                </div>
                                                <div class="col-12 col-md-6 col-lg-3">
                                                    <div class="form-group">
                                                        <label>CRECI</label>
                                                        <input type="text" v-model="broker.creci" class="form-control" placeholder="345898">
                                                    </div>
                                                </div>
                                                <div class="col-12 col-md-6 col-lg-3">
                                                    <div class="form-group">
                                                        <label>Imobiliária</label>
                                                        <!-- <select class="form-control selectsearch"  style="height: 48px !important" v-model="broker.real_state_id">
                                                             <option v-for="imobiliaria in imobiliarias" :value="imobiliaria.id">{{imobiliaria.fantasy_name}}</option>
                                                        </select>      -->

                                                         <select class="form-control"  id="imobiliaria" @change="mudaImobiliaria($event)"  style="height: 48px !important" v-model="broker.real_state_id">
                                                             <option v-for="imobiliaria in imobiliarias" :value="imobiliaria.id">{{imobiliaria.fantasy_name}}</option>
                                                        </select>                                                 
                                                    </div>
                                                </div>
                                                <div v-if="imobiliaria_allow_others=='sim'" class="col-12 col-md-6 col-lg-3">
                                                    <div class="form-group">
                                                        <label>Nome da imobiliária</label>
                                                        <input type="text" v-model="broker.mercado_name" class="form-control" placeholder="345898">                                           
                                                    </div>
                                                </div>
                                                <div class="col-12 col-md-6 col-lg-3">
                                                    <div class="form-group">
                                                        <label>Corretor desde </label>
                                                        <input v-model="broker.broker_at" type="date" class="form-control" placeholder="21/01/2021">
                                                    </div>
                                                </div>
                                                <div class="col-12 col-md-6 col-lg-3">
                                                    <div class="form-group">
                                                        <label>Data cadastro </label>
                                                        <input v-model="broker.created_at" type="date" class="form-control" placeholder="21/01/2021" disabled="">
                                                    </div>
                                                </div>
                                                <div class="col-12 col-md-6 col-lg-3">
                                                    <div class="form-group">
                                                        <label>Como conheceu a empresa? </label>
                                                        <select v-model="broker.source" class="custom-select mr-sm-2" id="inlineFormCustomSelect">
                                                            <option >Selecione</option>
                                                            <option value="GOOGLE">Google</option>
                                                            <option value="SOCIAL_MEDIA">Rede Social</option>
                                                            <option value="RADIO">Rádio</option>
                                                            <option value="OUTDOOR">Outdoor</option>
                                                            <option value="MAGAZINE">Revista</option>
                                                            <option value="INDICATION">Indicação</option>
                                                            <option value="OTHER">Outros</option>
                                                        </select>
                                                        <small id="name1" class="badge badge-default badge-info form-text text-white float-left">Definir a origem do lead</small>
                                                    </div>
                                                </div>
                                                <div class="col-12 pt-4"></div>
                                                <div class="col-12 col-md-6 col-lg-5">
                                                    <div class="form-group">
                                                        <label>Nome* </label>
                                                        <input required v-model="broker.first_name" type="text" class="form-control" placeholder="">
                                                        <div class="invalid-feedback pl-4 pb-3">Por favor! Informe o seu nome.</div>
                                                    </div>
                                                </div>
                                                <div class="col-12 col-md-6 col-lg-4">
                                                    <div class="form-group">
                                                        <label>Sobrenome* </label>
                                                        <input required v-model="broker.last_name"  type="text" class="form-control" placeholder="">
                                                        <div class="invalid-feedback pl-4 pb-3">Por favor! Informe o seu sobrenome.</div>
                                                    </div>
                                                </div>
                                                <div class="col-12 col-md-6 col-lg-3">
                                                    <div class="form-group">
                                                        <label>CPF* </label>
                                                        <input required v-model="broker.cpf"  v-mask="'###.###.###-##'" type="tel" class="form-control" value="1-(444)-444-4445">
                                                    </div>
                                                </div>
                                       
                                                <div class="col-12 col-md-6 col-lg-3">
                                                    <div class="form-group">
                                                        <label>Data de nascimento </label>
                                                        <input v-model="broker.birth_date" type="date" class="form-control" placeholder="">
                                                    </div>
                                                </div>
                                                <div class="col-12 col-md-6 col-lg-3">
                                                    <div class="form-group">
                                                        <label>Sexo </label>
                                                        <select v-model="broker.sex" class="custom-select mr-sm-2" id="inlineFormCustomSelect">
                                                            <option value="">Selecione</option>
                                                            <option >Masculino</option>
                                                            <option >Feminino</option>
                                                            <option >Não quero informar</option>
                                                        </select>
                                                    </div>
                                                </div>
                                                <div class="col-12 col-md-6 col-lg-3">
                                                    <div class="form-group">
                                                        <label>Estado civil </label>
                                                        <select v-model="broker.marital_status" class="custom-select mr-sm-2" id="inlineFormCustomSelect">
                                                            <option >Selecione</option>
                                                            <option >Solteiro(a)</option>
                                                            <option >Casado(a) Comunhão de Bens</option>
                                                            <option >Casado(a) Comunhão Parcial de Bens</option>
                                                            <option >Casado(a) Separação de Bens</option>
                                                            <option >Divorciado(a)</option>
                                                            <option >Separado(a) Judicialmente</option>
                                                            <option >Viúvo(a)</option>
                                                            <option >União Estável/Outros</option>
                                                        </select>
                                                    </div>
                                                </div>
                                                <div class="col-12 col-md-6 col-lg-3">
                                                    <div class="form-group">
                                                        <label>Grau de instrução </label>
                                                        <select v-model="broker.education_level" class="custom-select mr-sm-2" id="inlineFormCustomSelect">
                                                            <option >Selecione</option>
                                                            <option >Não Alfabetizado</option>
                                                            <option >Ensino Fundamental Incompleto</option>
                                                            <option >Ensino Fundamental Completo</option>
                                                            <option >Médio Incompleto</option>
                                                            <option >Médio Completo</option>
                                                            <option >Superior Incomplet</option>
                                                            <option >Superior Completo</option>
                                                            <option >Especialização2</option>
                                                            <option >Mestrado</option>
                                                            <option >Doutorado</option>
                                                        </select>
                                                    </div>
                                                </div>
                                                <div class="col-12 col-md-6 col-lg-3">
                                                    <div class="form-group">
                                                        <label>Nacionalidade </label>
                                                        <select  v-model="broker.nacionality" class="custom-select mr-sm-2" id="inlineFormCustomSelect">
                                                            <option >Selecione</option>
                                                            <option >Brasileira</option>
                                                            <option >Estrangeira</option>
                                                            <option >Brasileira Naturalizado</option>
                                                        </select>
                                                    </div>
                                                </div>
                                                <div class="col-12 col-md-6 col-lg-6">
                                                            <div class="form-group">
                                                                <label>Naturalidade </label>
                                                                <div class="row">
                                                                    <div class="col-12 col-md-6">
                                                                        <select class="custom-select mr-sm-2" name="uf" v-model="broker.born_state">
                                                                            <option :value="null">Selecione</option>
                                                                            <option v-for="item in states" :key="item.sigla" :value="item.sigla" >{{ item.nome }}</option>
                                                                        </select>
                                                                    </div>
                                                                    <div class="col-12 col-md-6">
                                                                        <select class="custom-select mr-sm-2" v-model="broker.born_city">
                                                                            <option :value="null">Selecione</option>
                                                                            <option v-for="item in getCities(broker.born_state)" :key="item" :value="item">{{ item }}</option>
                                                                        </select>
                                                                    </div>
                                                                </div>
                                                                
                                                            </div>
                                                        </div>
                                                <div class="col-12"></div>
                                                <div class="col-12 col-md-6 col-lg-6">
                                                    <div class="form-group">
                                                        <label>Nome do pai </label>
                                                        <input  v-model="broker.father_name"  type="text" class="form-control" placeholder="">
                                                    </div>
                                                </div>
                                                <div class="col-12 col-md-6 col-lg-6">
                                                    <div class="form-group">
                                                        <label>Nome do mãe </label>
                                                        <input  v-model="broker.mother_name" type="text" class="form-control" placeholder="">
                                                    </div>
                                                </div>
                                                <div class="col-12"></div>
                                                <div class="col-12 col-md-6 col-lg-3">
                                                    <div class="form-group">
                                                        <label>Número da identidade </label>
                                                        <input v-model="broker.rg" type="tel" class="form-control" placeholder="">
                                                    </div>
                                                </div>
                                                <div class="col-12 col-md-6 col-lg-3">
                                                    <div class="form-group">
                                                        <label>Órgão emissor </label>
                                                        <input v-model="broker.rg_emissor" type="text" class="form-control" placeholder="">
                                                    </div>
                                                </div>
                                                <div class="col-12 col-md-6 col-lg-3">
                                                    <div class="form-group">
                                                        <label>UF </label>
                                                        <select v-model="broker.rg_uf" class="custom-select mr-sm-2" id="inlineFormCustomSelect">
                                                            <option :value="null">Selecione</option>
                                                             <option v-for="item in states" :key="item.sigla" :value="item.sigla" >{{ item.nome }}</option>
                                                           
                                                        </select>
                                                    </div>
                                                </div>
                                                <div class="col-12 col-md-6 col-lg-3">
                                                    <div class="form-group">
                                                        <label>Data de emissão </label>
                                                        <input v-model="broker.rg_date" type="date" class="form-control" placeholder="">
                                                    </div>
                                                </div>
                                                <div class="col-12"></div>
                                                <br>
                                                <div class="col-12 py-2">
                                                    <h4 class="card-title">Contatos</h4>
                                                </div>
                                                <div class="col-12 col-md-6 col-lg-4">
                                                    <div class="form-group">
                                                        <label>E-mail* </label>
                                                        <input required v-model="broker.email"  type="email" class="form-control" value="abc@example.com">
                                                    </div>
                                                </div>
                                                <div class="col-12 col-md-6 col-lg-4">
                                                    <div class="form-group">
                                                        <label>Telefone </label>
                                                        <div class="d-flex">
                                                            <AreaCode :defaultCountry="broker.telephone_area_code_iso" @setIsoCode="setTelephoneIsoCode" @setAreaCode="setTelephoneAreaCode"/>
                                                            <input type="tel" class="form-control" placeholder="(44) 4 4444-4444" v-model="broker.telephone" v-mask="['(##) ####-####', '(##) # ####-####']" >
                                                        </div>
                                                        
                                                    </div>
                                                </div>
                                                <div class="col-12 col-md-6 col-lg-4">
                                                    <div class="form-group">
                                                        <label>Whatsapp* </label>
                                                        <div class="d-flex">
                                                            <AreaCode :defaultCountry="broker.whatsapp_area_code_iso"  @setIsoCode="setWhatsAppIsoCode" @setAreaCode="setWhatsAppAreaCode"/>
                                                            <input required  type="tel" class="form-control" placeholder="(44) 4 4444-4444" v-model="broker.whatsapp" v-mask="['(##) ####-####', '(##) # ####-####']" >
                                                        </div>
                                                        
                                                    </div>
                                                </div>
                                            </div>
                                        </div>                                    
                                </div>
                                <div class="tab-pane" id="redessociais">
                                    <form action="" class="px-2 needs-validation" novalidate="">
                                        <div class="form-body">
                                            <div class="row">
                                                <div class="col-12 col-md-6 col-lg-6 py-2">
                                                    <div class="input-group">
                                                        <div class="input-group-prepend">
                                                            <div class="input-group-text" id="btnGroupAddon2"><i class="fab fa-facebook"></i></div>
                                                        </div>
                                                        <input type="text" class="form-control" placeholder="" aria-label="Input group example" aria-describedby="btnGroupAddon2">
                                                    </div>
                                                </div>
                                                <div class="col-12 col-md-6 col-lg-6 py-2">
                                                    <div class="input-group">
                                                        <div class="input-group-prepend">
                                                            <div class="input-group-text" id="btnGroupAddon2"><i class="fab fa-instagram"></i></div>
                                                        </div>
                                                        <input type="text" class="form-control" placeholder="" aria-label="Input group example" aria-describedby="btnGroupAddon2">
                                                    </div>
                                                </div>
                                                <div class="col-12 col-md-6 col-lg-6 py-2">
                                                    <div class="input-group">
                                                        <div class="input-group-prepend">
                                                            <div class="input-group-text" id="btnGroupAddon2"><i class="fab fa-linkedin-in"></i></div>
                                                        </div>
                                                        <input type="text" class="form-control" placeholder="" aria-label="Input group example" aria-describedby="btnGroupAddon2">
                                                    </div>
                                                </div>
                                                <div class="col-12 col-md-6 col-lg-6 py-2">
                                                    <div class="input-group">
                                                        <div class="input-group-prepend">
                                                            <div class="input-group-text" id="btnGroupAddon2"><i class="fab fa-twitter"></i></div>
                                                        </div>
                                                        <input type="text" class="form-control" placeholder="" aria-label="Input group example" aria-describedby="btnGroupAddon2">
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="form-actions">
                                            <div class="text-right">
                                                <button type="reset" class="btn btn-dark">Cancelar</button>
                                                <button type="submit" class="btn btn-info">Cadastrar</button>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                               
                                <div class="tab-pane" id="configuracoes">  
                                        <div class="form-body">
                                            <div class="row">   
                                                <div class="col-12 col-md-6 col-lg-4">
                                                    <div class="form-group">
                                                        <label>Status do cadastro </label>
                                                        {{broker.is_active}}
                                                        <select v-model="broker.is_active" class="custom-select mr-sm-2" id="inlineFormCustomSelect">
                                                            <option :value="1">Ativo</option>
                                                            <option :value="0">Inativo</option>
                                                        </select>
                                                        <small id="name13" class="badge badge-default badge-danger form-text text-white">Desativar o acesso do corretor na plataforma</small>
                                                    </div>
                                                </div>
                                                <div  class="col-12 pt-4 pb-2">
                                                    <h4 class="card-title">Senha de acesso</h4>
                                                </div>
                                                <div class="col-12 col-md-6 col-lg-4">
                                                    <div class="form-group">
                                                        <label>Resetar senha </label>
                                                        <input v-model="broker.password" name="password" type="password" class="form-control" id="password" placeholder="********">
                                                    </div>
                                                </div>
                                                <div class="col-12 col-md-6 col-lg-4">
                                                    <div class="form-group">
                                                        <label>Confirmar senha </label>
                                                        <input v-model="broker.password_confirmation" type="password" class="form-control" id="passtext" placeholder="********">
                                                    </div>
                                                    <small id="name1" class="badge badge-default badge-info form-text text-white float-left">Deve ter entre 8 e 20 caracteres.</small>
                                                </div>
                                                <div class="col-12">
                                                    <div class="form-group">
                                                        <div class="col-12 py-2">
                                                            <i class="fab fa-facebook font-20 align-middle"></i> Rafael Dias
                                                        </div>
                                                        <button type="submit" class="btn btn-danger">Desvincular conta <i class="pl-1 far fa-trash-alt"></i></button>
                                                    </div>
                                                </div>                                               
                                            </div>
                                           
                                        </div>
                                       
                                </div>
                            </div> 
                                    </b-tab>
    

    
                                    <b-tab class="tab-pane" id="enderecoresidencial">
                                        <template #title>
                                            <div class="d-flex">
                                                <i class="far fa-map font-20 align-middle mr-2"></i><span class="d-none d-lg-block"> Endereço residencial <b-badge variant="danger" v-show="address_error">1</b-badge></span>
                                            </div>
                                        </template>

                                        <div class="tab-pane" id="enderecoresidencial">
                                            <form action="" class="px-2 needs-validation" novalidate="">
                                                <div class="form-body">
                                                    <div class="row">
                                                        <div class="col-12 col-md-4 col-lg-3">
                                                            <div class="form-group">
                                                                <label>CEP </label>
                                                                <input v-model="broker.cep" @blur="getAddress()"  v-mask="'#####-###'" type="tel" class="form-control" value="74.830-50">
                                                            </div>
                                                        </div>
                                                        <div class="col-12"></div>
                                                        <div class="col-12 col-md-6 col-lg-4">
                                                            <div class="form-group">
                                                                <label>Rua/Avenida/Alameda/Travessa </label>
                                                                <input v-model="broker.street"  type="text" class="form-control" placeholder="">
                                                            </div>
                                                        </div>
                                                        <div class="col-12 col-md-6 col-lg-2">
                                                            <div class="form-group">
                                                                <label>Número </label>
                                                                <input v-model="broker.number"  type="text" class="form-control" placeholder="">
                                                            </div>
                                                        </div>
                                                        <div class="col-12 col-md-6 col-lg-6">
                                                            <div class="form-group">
                                                                <label>Complemento </label>
                                                                <input v-model="broker.complement" type="text" class="form-control" placeholder="">
                                                            </div>
                                                        </div>
                                                        <div class="col-12 col-md-6 col-lg-6">
                                                            <div class="form-group">
                                                                <label>Bairro </label>
                                                                <input v-model="broker.neighborhood"  type="text" class="form-control" placeholder="">
                                                            </div>
                                                        </div>
                                                        <div class="col-12 col-md-6 col-lg-2">
                                                            <div class="form-group">
                                                                <label>UF </label>
                                                                <select v-model="broker.state" class="custom-select mr-sm-2" id="inlineFormCustomSelect">
                                                                    <option :value="null">Selecione</option>
                                                                     <option v-for="item in states" :key="item.sigla" :value="item.sigla" >{{ item.nome }}</option>
                                                           
                                                                </select>
                                                            </div>
                                                        </div>
                                                        <div class="col-12 col-md-6 col-lg-4">
                                                            <div class="form-group">
                                                                <label>Município </label>
                                                                <select v-model="broker.city"  class="custom-select mr-sm-2" id="inlineFormCustomSelect">
                                                                    <option :value="null">Selecione</option>
                                                                            <option v-for="item in getCities(broker.state)" :key="item" :value="item">{{ item }}</option>
                                                           
                                                                </select>
                                                            </div>
                                                        </div>
                                                        
                                                        <div class="col-12 col-md-6 col-lg-3">
                                                            <div class="form-group">
                                                                <label>Sua residência é </label>
                                                                <select  v-model="broker.residence_type" class="custom-select mr-sm-2" id="inlineFormCustomSelect">
                                                                    <option :value="null">Selecione</option>
                                                                    <option value="LEASED">Alugada</option>
                                                                    <option value="PARENTS">Mora com parentes/pais</option>
                                                                    <option value="GIVEN">Cedida/Funcional</option>
                                                                    <option value="OWN">Própria quitada</option>
                                                                    <option value="FINANCED_CAIXA">Financiada pela CAIXA</option>
                                                                    <option value="FINANCED">Financiada por outras entidades</option>
                                                                    <option value="OTHERS">Outros</option>
                                                                </select>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            
                                            </form>
                                        </div>
                                                
                                    </b-tab>
                             
                                    <b-tab class="tab-pane" id="imoveis">
                                        <template #title>
                                            <div class="d-flex">
                                                <i class="fas fa-globe font-20 align-middle mr-2"></i><span class="d-none d-lg-block"> Rede sociais <b-badge variant="danger" v-show="enterprise_error">1</b-badge></span>
                                            </div>
                                        </template>
                                           
                                        <div class="tab-pane" id="redessociais">
                                            <form action="" class="px-2 needs-validation" novalidate="">
                                                <div class="form-body">
                                                    <div class="row">
                                                        <div class="col-12 col-md-6 col-lg-6 py-2">
                                                            <div class="input-group">
                                                                <div class="input-group-prepend">
                                                                    <div class="input-group-text" id="btnGroupAddon2"><i class="fab fa-facebook"></i></div>
                                                                </div>
                                                                <input v-model="broker.facebook_url" type="text" class="form-control" placeholder="" aria-label="Input group example" aria-describedby="btnGroupAddon2">
                                                            </div>
                                                        </div>
                                                        <div class="col-12 col-md-6 col-lg-6 py-2">
                                                            <div class="input-group">
                                                                <div class="input-group-prepend">
                                                                    <div class="input-group-text" id="btnGroupAddon2"><i class="fab fa-instagram"></i></div>
                                                                </div>
                                                                <input v-model="broker.instagram_url"  type="text" class="form-control" placeholder="" aria-label="Input group example" aria-describedby="btnGroupAddon2">
                                                            </div>
                                                        </div>
                                                        <div class="col-12 col-md-6 col-lg-6 py-2">
                                                            <div class="input-group">
                                                                <div class="input-group-prepend">
                                                                    <div class="input-group-text" id="btnGroupAddon2"><i class="fab fa-linkedin-in"></i></div>
                                                                </div>
                                                                <input v-model="broker.linkedin_url" type="text" class="form-control" placeholder="" aria-label="Input group example" aria-describedby="btnGroupAddon2">
                                                            </div>
                                                        </div>
                                                        <div class="col-12 col-md-6 col-lg-6 py-2">
                                                            <div class="input-group">
                                                                <div class="input-group-prepend">
                                                                    <div class="input-group-text" id="btnGroupAddon2"><i class="fab fa-twitter"></i></div>
                                                                </div>
                                                                <input v-model="broker.twitter_url" type="text" class="form-control" placeholder="" aria-label="Input group example" aria-describedby="btnGroupAddon2">
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                
                                            </form>
                                        </div>
                                         
                                    </b-tab>

                                    <b-tab class="tab-pane" id="configuracoes">
                                        <template #title>
                                            <div class="d-flex">
                                                <i class="far fa-sun font-20 align-middle mr-2"></i><span class="d-none d-lg-block"> Configurações <b-badge variant="danger" v-show="settings_error">1</b-badge></span>
                                            </div>
                                        </template>
                                            <div class="form-body">
                                                <div class="row">
                                                    <div class="col-12 col-md-6 col-lg-4">
                                                        <div class="form-group">
                                                            <label>Status do cadastro </label>
                                                            <select class="custom-select mr-sm-2" v-model="broker.is_active">
                                                                <option :value="1">Ativo</option>
                                                                <option :value="0">Inativo</option>
                                                            </select>
                                                        </div>
                                                        <small id="name13" class="badge badge-default badge-danger form-text text-white">Desativar o acesso do corretor na plataforma</small>
                                                    </div>
                                                    <div class="col-12 mt-3">
                                                        <div class="form-group">
                                                            <label>Sua foto</label>
                                                            <div class="input-group mb-3">
                                                                <div class="input-group-prepend w-100 mb-3">                                                                   
                                                                    <span class="input-group-text">Upload</span>
                                                                    <div class="custom-file">
                                                                        <input  type="file" multiple class="custom-file-input" @change="setFile" id="inputGroupFile01">
                                                                        <label class="custom-file-label" for="inputGroupFile01">{{broker?.image?.name || ""}}</label>
                                                                    </div>  
                                                                </div>
                                                                <div  v-if="broker.image !== '' && broker.image != null" class="mt-3">
                                                                    <img width="100px" :src="broker.image" />
                                                                </div>
                                                                <div v-else>
                                                                    <img width="100px" id="file1"  />
                                                                </div>
                                                            </div>
                                                        </div> 
                                                    </div>    
                                                    <div class="col-12 pt-4 pb-2">
                                                        <h4 class="card-title">Senha de acesso {{broker.password}}</h4>
                                                    </div>
                                                    <div class="col-12 col-md-6 col-lg-4">
                                                        <div class="form-group">
                                                            <label>Resetar senha </label>
                                                            <input type="password" class="form-control" id="passtext" placeholder="********" v-model="broker.password" ref="password"  :class="{'is-invalid': errors.has('password')}">
                                                        </div>
                                                    </div>
                                                    <div class="col-12 col-md-6 col-lg-4">
                                                        <div class="form-group">
                                                            <label>Confirmar senha </label>
                                                            <input type="password" class="form-control" id="passtext"  v-model="broker.password_confirmation"  placeholder="********" :class="{'is-invalid': errors.has('confirm_password')}">
                                                        </div>
                                                        <small id="name1" class="badge badge-default badge-info form-text text-white float-left">Deve ter entre 8 e 20 caracteres.</small>
                                                    </div>
                                                    <div class="col-12" v-show="false">
                                                        <div class="form-group">
                                                            <div class="col-12 py-2">
                                                                <i class="fab fa-facebook font-20 align-middle"></i> {{ broker.first_name }} {{ broker.last_name }}
                                                            </div>
                                                            <button type="submit" class="btn btn-danger">Desvincular conta <i class="pl-1 far fa-trash-alt"></i></button>
                                                        </div>
                                                    </div>

                                                   
                                                </div>
                                            </div>
                                 
                                    </b-tab>
                                   
                                    <div class="form-action">
                                        <div class="text-right">
                                            <router-link :to="{name: 'Broker'}" class="btn btn-dark">Cancelar</router-link>
                                            <button  type="submit" class="btn btn-info ml-2">{{ id ? 'Atualizar' : 'Cadastrar' }}</button>
                                         
                                        </div> 
                                    </div>
                                    </form>
                                </b-tabs>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    </template>
    <script>
    import BrokerService from "@/services/resources/BrokerService";
    import ImobiliariaService from "@/services/resources/ImobiliariaService";
    import VueUploadMultipleImage from "vue-upload-multiple-image";

    import states from '@/assets/js/states.json'
    import axios from 'axios';
    import AreaCode  from "@/components/AreaCode.vue";
    import VerifyDataAvailableService from "@/services/resources/VerifyDataAvailableService";
    import Cookies from "js-cookie";
    
    const dataAvailable = VerifyDataAvailableService.build();
    const service = BrokerService.build();
    var imobiliariaService = ImobiliariaService.build()
    export default {
        components: {
            AreaCode,
            VueUploadMultipleImage
        },
       data(){
           return{
               broker:{
                    "email" : "",
                    "password" : "",
                    "password_confirmation": "",
                    "first_name" : "",
                    "last_name" : "",
                    "cpf" : "",
                    "document" : "",
                    "image": "",
                    "birth_date" : "",
                    "sex": "Selecione",
                    "marital_status" : "Selecione",
                    "education_level" : "Selecione",
                    "nacionality" : "Selecione",
                    "born_state" : "",
                    "born_city" : "",
                    "father_name" : "",
                    "mother_name" : "",
                    "rg" : "",
                    "rg_emissor" : "",
                    "rg_uf" : "",
                    "rg_date" : "",
                    "telephone" : "",
                    "whatsapp" : "",
                    "creci" : "",
                    "real_state_id" : "",
                    "broker_at" : "",
                    "source" : "Selecione",
                    "cep" : "",
                    "street" : "",
                    "number" : "",
                    "complement" : "",
                    "neighborhood" : "",
                    "city" : "",
                    "state" : "",
                    "is_active" :1,
                    "residence_type" : "",
                    "facebook_url" : "",
                    "instagram_url" : "",
                    "linkedin_url" : "",
                    "twitter_url" : "",
                    "whatsapp_area_code": "55",
                    "whatsapp_area_code_iso": "BR",
                    "telephone_area_code": "55",
                    "telephone_area_code_iso": "BR"
                },
                imobiliarias: [],
        
               pages:[
                    {
                        name: 'dashboard',
                        to: 'Dashboard'
                    },
                    {
                        name: 'novo corretor',
                        to: 'BrokerNew'
                    }
                ],
                menu:[
                    {
                        name: 'Novo corretor',
                        to: 'BrokerNew'
                    },
                    {
                        name: 'Corretores',
                        to: 'Broker'
                    }
                ],
                states: [],
                address_error: false,
                settings_error: false,
                enterprise_error: false,
                legal_entity_error: false,
                basic_error: false,
                error_information: false,
                id: null,
                lock: false,              
                imobiliaria_allow_others: 'não'
           }
       },
       computed:{
         
       },
       methods:{  
        uploadImageSuccess(formData, index, fileList, option = null) {
        this.broker.image = fileList;
      
       
    },

    beforeRemove(index, done, fileList, option = null) {
        var r = confirm("Remover imagem?");
        if (r == true) {

            if(option === 'image') {
                this.broker.image.splice(index, 1);
            } else {
                this.broker.image.splice(index, 1);
            }

          
        }
    },
    editImage(formData, index, fileList, option = null) {

        if(option === 'image') {
            this.broker.image = fileList.path;
        } else {
            this.broker.image = fileList.path;
        }
    },
        getRealImobiliarias() {
                let data = {
                    company_id: Cookies.get('company_id'),
                  
                };
                imobiliariaService.search(data)
                .then(resp => {
                    this.imobiliarias = resp.data
                })
            },       
        setTelephoneIsoCode(value) {
            this.$set(this.broker, 'telephone_area_code_iso', value);
        },
        setWhatsAppIsoCode(value) {
            this.$set(this.broker, 'whatsapp_area_code_iso', value);
        },
        setTelephoneAreaCode(value) {
            this.$set(this.broker, 'telephone_area_code', value);
        },
        setWhatsAppAreaCode(value) {
                this.$set(this.broker, 'whatsapp_area_code', value);
            },
           getAddress(){
              
              if(this.broker.cep.length === 10 || this.broker.cep.length === 8) {
                let url = `https://viacep.com.br/ws/${this.broker.cep.replace('-', '').replace('.', '')}/json`;
    
                    axios.get(url).then(resp =>{
                        this.broker.street = resp.data.logradouro;
                        this.broker.neighborhood = resp.data.bairro;
                        this.broker.state = resp.data.uf;
                        this.broker.city = resp.data.localidade;
                    });
              }
    
       
           },
          
           getCities(uf){
               
               if(!uf)
                    return []
    
               let state = this.states.filter(elem => {
                   return elem.sigla === uf;
               });
               if(!state.length)
                    return state;
                    
               return state[0].cidades;
    
           },

           setFile(eve) {  
            var reader = new FileReader();
            reader.onload = function (eve2) {              
              this.broker["image"] =  eve2.target.result;
              $('#file1').attr('src', eve2.target.result);
            }.bind(this)          
            reader.readAsDataURL(eve.target.files[0]);           
        },
           save(){
            

         

        
        if(this.id){
                if (this.broker.password == null || this.broker.password == "") {
                    this.broker.password = ""
                }
             //   this.image =  this.broker.image[0].path
                service
                .update({
                    ...this.broker,
                    social_media : {
                            facebook_url : this.broker.facebook_url,
                            instagram_url : this.broker.instagram_url,
                            linkedin_url : this.broker.linkedin_url,
                            twitter_url : this.broker.twitter_url
                    },
                    id: this.id
                })
                .then(resp => {
                    this.$bvToast.toast('Seu corretor foi atualizado com sucesso!', {
                        title: 'Corretor atualizado',
                        autoHideDelay: 5000,
                        type: 'success'
                    })
                    this.fetchBroker();
                })
                .catch(err => {
                    this.$bvToast.toast('Verifique se todos os campos estão preenchidos ou se o WhatsApp já está sendo utilizado', {
                        title: 'Criação do corretor',
                        autoHideDelay: 5000,
                        type: 'danger'
                    })
                })
            }else{
                //this.image =  this.broker.image[0].path
                service
                .create({
                    ...this.broker,
                    social_media : {
                            facebook_url : this.broker.facebook_url,
                            instagram_url : this.broker.instagram_url,
                            linkedin_url : this.broker.linkedin_url,
                            twitter_url : this.broker.twitter_url
                    }
                })
                .then(resp => {
                    this.$bvToast.toast('Seu corretor foi criado com sucesso!', {
                        title: 'Corretor criado',
                        autoHideDelay: 5000,
                        type: 'success'
                    })
                    this.fetchBroker();
                })
                .catch(err => {
                    console.log(err);
                
                    this.$bvToast.toast('Verifique se todos os campos estão preenchidos corretamente e se o WhatsApp, email ou CPF já não estão sendo utilizados', {
                        title: 'Criação do corretor',
                        autoHideDelay: 5000,
                        type: 'danger   '
                    })
                })
              }
            
           },

           mudaImobiliaria(eve) {
            let opcao=document.getElementById("imobiliaria").options[eve.srcElement.options.selectedIndex].value
           
              this.imobiliarias.forEach((ele) => {
                if(ele.id == opcao){
                    this.imobiliaria_allow_others=ele.allow_others
                }
                
            });
            this.$data.broker.real_state_id = opcao
           },
           
           
           fetchBroker(){
    
                if(!this.id){
                    return;
                }
    
                let data = {
                    id: this.id
                }
    
                service
                .read(data)
                .then(resp => {
                    setTimeout(() => {
                         this.imobiliarias.forEach((ele) => {
                            if(ele.id ==resp.broker.real_state_id){
                        
                                this.imobiliaria_allow_others=ele.allow_others
                             }
                               
                           
                            
                        });
                    }, 300);
                       
                     this.$nextTick(() => {
                      $(".selectsearch").val(resp.broker.real_state_id)
                    })

                    this.broker = {...resp, 
                        ...resp.broker, 
                        ...resp.broker.social_media, 
                        id: resp.id, created_at: resp.created_at.split('T')[0],
                        is_active: resp.broker.is_active
                    };
                 
           
                })
                .catch(err => {
                 
                console.log(err)
                })
    
           },
       },
       mounted(){
           
        $(document).ready(() => {
            $('.selectsearch').select2({
                "language":{
                    "noResults" : function () { return 'Busca não encontrada'; }
                }                                    
            }); 
           
        });

        $(".selectsearch").on("change", ()=> {  
            this.imobiliarias.forEach((ele) => {
                if(ele.id == $(".selectsearch").val()){
                    this.imobiliaria_allow_others=ele.allow_others
                }
                
            });
            this.$data.broker.real_state_id = parseInt($(".selectsearch").val())
        }); 

        $('[type="submit"]').on('click', function () {
            $(this)
            .closest('form')
            .find('[required]')
            .addClass('required');              
        });
      
           this.states = states;
           this.id = this.$route.params.id;
           this.fetchBroker();
           this.getRealImobiliarias()
       }
    }

 
    </script>

    <style>  
        input.required:invalid    {
         border-color: red !important;
       }
    </style>
